import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import LandingPage from "@/views/LandingPage";
import HowItWorks from "@/views/HowItWorks";
import MakePredictionsSoccer from "@/views/MakePredictionsSoccer";
import MakePredictionsRugby from "@/views/MakePredictionsRugby";
import MyResults from "@/views/MyResults";
import NoRoundsPage from "@/views/NoRoundsPage";

const routes = [
    {
        path: '/',
        redirect: 'landing-page'
    },
    {
        path: '/landing-page',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        component: HowItWorks,
    },
    {
        path: '/make-predictions-soccer/:roundId',
        name: 'MakePredictionsSoccer',
        component: MakePredictionsSoccer,
    },
    {
        path: '/make-predictions-rugby/:roundId',
        name: 'MakePredictionsRugby',
        component: MakePredictionsRugby,
    },
    {
        path: '/no-rounds-predict',
        name: 'NoRoundsPage',
        component: NoRoundsPage,
    },
    {
        path: '/my-results/:sportId',
        name: 'MyResults',
        component: MyResults,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next)
})


export default router
