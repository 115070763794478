<template>
  <div class="d-flex flex-column align-items-center body-container text-center">
    <img class=" mt-3 col-12" src="../assets/game/header-icon-2.png">
    <img class="col-7" src="../assets/game/tshirt-on-clothsline.png">
    <div>There are no rounds to predict today.</div>
    <div class="col-10 mt-3">Predict more of the world's greatest leagues when you play <strong>Vodacom Fantasy League
      VIP</strong> today!
    </div>
    <button v-if="subscriptionStatus !== 'Subscribed'" @click="onButtonClicked()" class="join-vip-button mt-3 col-11">
      JOIN VIP NOW
    </button>
    <button v-if="subscriptionStatus === 'Subscribed'" class="join-vip-button mt-3 col-11"
            @click="onButtonClicked">PLAY FANTASY FOOTBALL
    </button>
    <button @click="backButtonClicked()" class="back-button col-9 mt-2">Back</button>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NoRoundsPage",
  computed: {
    ...mapState(['subscriptionStatus'])
  },
  methods: {
    onButtonClicked() {
      my.postMessage();
    },
    backButtonClicked() {
      this.$router.push('/landing-page');
    },
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  }
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
}

.join-vip-button {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 50px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
}

.back-button {
  padding-bottom: 5px;
  height: 45px;
  border-radius: 10px;
  border: 0.5px solid #EF151C;
  font-size: 16px;
}

</style>
