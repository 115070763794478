<template>
  <div class="d-flex flex-column align-items-center body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center col-12 mt-3">
      <img src="../assets/game/header-icon-2.png">
    </div>
    <div class="d-flex flex-row justify-content-around round-date-time col-11 mt-3 p-1">
      <div>{{ this.formattedDate }}</div>
      <div>{{ this.formattedTime }}</div>
    </div>
    <div class="d-flex flex-column match-badges-incrementer-container pt-2 col-11"
         v-for="(fixture, index) in leagueFixturesArray" :key="index">
      <div v-if="checkMatchNotStarted(fixture)">
        <div class="d-flex flex-row pt-2 pb-2">
          <div class="d-flex flex-column align-items-center col-5">
            <img class="badge-icon" :src="this.findImageModule(fixture.Team1Name)[0]">
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
          </div>
          <div class="d-flex flex-row align-items-center col-7">
            <div @click="minusButtonClicked(index, 1)"
                 class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
            </div>
            <div class="col-3 text-center score-value">{{ fixture.Team1Score }}</div>
            <div @click="plusButtonClicked(index, 1)"
                 class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
            </div>
          </div>
        </div>
        <div class="d-flex flex-row pt-2 pb-3">
          <div class="d-flex flex-column align-items-center col-5">
            <img class="badge-icon" :src="this.findImageModule(fixture.Team2Name)[0]">
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
          </div>
          <div class="d-flex flex-row align-items-center col-7">
            <div @click="minusButtonClicked(index, 2)"
                 class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
            </div>
            <div class="col-3 text-center score-value">{{ fixture.Team2Score }}</div>
            <div @click="plusButtonClicked(index, 2)"
                 class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center pt-3 col-11">
      <button @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled" class="submit-predictions-button">Submit Predictions</button>
    </div>
    <div class="d-flex flex-column justify-content-center col-9">
      <button @click="backButtonClicked" class="mt-3 back-button">Back</button>
    </div>
    <div v-if="successfulSubmissionPopup"
         class="d-flex flex-column prediction-submitted-container align-items-center col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="d-flex flex-column col-11">
        <div class="col-11 submission-header-text mt-4">
          PREDICTION SUBMITTED SUCCESSFULLY
          <img @click="editPredictionsClicked()" class="close-popup-button" src="../assets/game/btn_close.png">
        </div>
        <div v-if="subscriptionStatus === 'Subscribed'" class="col-11 submission-secondary-text mt-2">
          Thanks for predicting the score for this weeks round. All the points you earn will be entered into the grand
          prize draw.
        </div>
        <div v-if="subscriptionStatus !== 'Subscribed'" class="col-11 submission-secondary-text-free mt-1">
          WIN airtime & data daily by building teams from top global leagues or predicting your favorite PSL to EPL team's results in domestic and international matches!
        </div>
        <div v-if="subscriptionStatus !== 'Subscribed'" class="col-11 submission-secondary-text-free mt-1">
          Join Vodacom Fantasy Leagues VIP today! And for first time users, your first day is free.
        </div>
        <div class="d-flex flex-column align-items-center mt-4">
          <img v-if="subscriptionStatus === 'Subscribed'" class="col-9" src="../assets/game/fantasy-user-illustration.png">
          <img v-if="subscriptionStatus !== 'Subscribed'" class="col-6" src="../assets/game/free-user-illustration.png">
          <div class="d-flex flex-row col-12 mt-3">
            <div @click="editPredictionsClicked()" class="d-flex flex-column align-items-center justify-content-center m-1 edit-prediction-button">EDIT PREDICTION</div>
            <div @click="homeButtonClicked()" class="d-flex flex-column align-items-center justify-content-center m-1 home-button">
              <img class="col-4" src="../assets/game/home-black.png">
            </div>
          </div>
          <button v-if="subscriptionStatus === 'Subscribed'" class="mt-2 mb-4 join-now-button"
                  @click="onPlayFantasyFootballClicked()">PLAY FANTASY FOOTBALL
          </button>
          <button v-if="subscriptionStatus !== 'Subscribed'" class="mt-2 join-now-button"
                  @click="onJoinNowClicked()">JOIN VIP
          </button>
          <div v-if="subscriptionStatus !== 'Subscribed'" class="mt-1 emphasized mb-4">
            T&C's Apply. Subscription Service. 1st Day Free. R3/Day
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import GameDataService from "@/services/gameDataService";
import {mapMutations, mapState} from "vuex";
import moment from "moment";

export default {
  name: "MakePredictionsSoccer",
  data() {
    return {
      successfulSubmissionPopup: false,
      leagueFixturesArray: undefined,
      submitButtonDisabled: false,
      displayClubName: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'roundEntered', 'user', 'subscriptionStatus'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setRoundEntered']),
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/teamBadges/soccer/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`@/assets/teamBadges/soccer/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    minusButtonClicked(index, teamNumber) {
      if (teamNumber === 1) {
        if (this.leagueFixturesArray[index].Team1Score > 0) --this.leagueFixturesArray[index].Team1Score
      } else if (this.leagueFixturesArray[index].Team2Score > 0) {
        --this.leagueFixturesArray[index].Team2Score
      }
    },
    plusButtonClicked(index, teamNumber) {
      teamNumber === 1 ? ++this.leagueFixturesArray[index].Team1Score : ++this.leagueFixturesArray[index].Team2Score;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.leagueFixturesArray.asyncForEach(async (match) => {
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.$route.params.roundId
        fixture.soccerFixtureId = match.FixtureID ? match.FixtureID : match.SoccerFixtureID;
        fixture.eventTypeId = 1;
        fixture.team1Id = match.Team1Id
        fixture.team2Id = match.Team2Id
        fixture.team1Score = match.Team1Score;
        fixture.team2Score = match.Team2Score;
        await GameDataService.enterSoccerRoundPredictions(fixture, this.user.msisdn);
      });
      this.successfulSubmissionPopup = true;
    },
    backButtonClicked() {
      this.$router.push('/landing-page');
    },
    editPredictionsClicked() {
      this.submitButtonDisabled = false;
      this.successfulSubmissionPopup = false;
    },
    homeButtonClicked() {
      this.setRoundEntered(true);
      this.$router.push('/landing-page');
    },
    onJoinNowClicked() {
      my.postMessage();
    },
    onPlayFantasyFootballClicked() {
      my.postMessage();
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    async checkIfRoundPreviouslyEntered() {
      this.leagueFixturesArray = [];
      let fixturesEnteredResponse = await GameDataService.getPlayerRoundPredictionsLive(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      if (fixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.leagueFixturesArray = fixturesEnteredResponse.data.roundPredictions;
      } else {
        let leagueFixtures = await GameDataService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
        this.addRoundFixturesDefault(leagueFixtures.data.fixtures);
      }
    },
    addRoundFixturesDefault(fixtures) {
      this.leagueFixturesArray = []
      fixtures.forEach((fixture) => {
        fixture.Team1Score = 0;
        fixture.Team2Score = 0;
        this.leagueFixturesArray.push(fixture);
      });
    },
    dateConversion(fixtures) {
      this.formattedDate = moment(fixtures[0].StartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtures[0].StartDate).format('h:mma');
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered()
    this.dateConversion(this.leagueFixturesArray);
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 50px;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  font-size: 15px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.match-badges-incrementer-container {
  border-bottom: .5px solid #a8a8a8;
}

.badge-icon {
  width: 40px;
}

.minus-decrement {
  height: 40px;
  font-weight: 700;
  border-radius: 14px 0px 0px 0px;
  font-size: 22px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.score-value {
  font-weight: 700;
  font-size: 22px;
}

.plus-incrementer {
  height: 40px;
  font-weight: 700;
  border-radius: 0px 0px 14px 0px;
  font-size: 22px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.match-badges-incrementer-container {
  border-bottom: .5px solid #a8a8a8;
}

.submit-predictions-button {
  border-radius: 12px;
  height: 50px;
  font-size: 18px;
  border: 0;
  color: #FFFFFF;
  background-color: #EF151C;
}

.back-button {
  height: 45px;
  border-radius: 10px;
  border: 0.5px solid #EF151C;
  font-size: 15px;
  background-color: #e8e8e8;
}

.prediction-submitted-container {
  position: fixed;
  bottom: 0;
  color: #000000;
  border-bottom-right-radius: 35px;
  border-top-left-radius: 35px;
  background-image: url("../assets/game/upsell-background.png");
  background-size: cover;
  background-position: center;
}

.submission-header-text {
  position: relative;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.1;
}

.join-now-button {
  background-color: #EF151C;
  color: #FFFFFF;
  border: 0;
  border-radius: 2px;
  height: 45px;
  font-weight: 700;
  font-size: 16px;
  width: 98%;
}

.edit-prediction-button, .home-button {
  height: 40px;
  border-radius: 2px;
  border: 2px solid #EF151C;
  font-size: 16px;
  font-weight: 800;
}

.edit-prediction-button {
  width: 77%;
}

.home-button {
  width: 23%;
}

.team-name {
  font-size: 10px;
}

.submission-secondary-text {
  font-size: 16px;
}

.submission-secondary-text-free {
  font-size: 14px;
}

.emphasized {
  font-style: italic;
  font-size: 10px;
}

.close-popup-button {
  position: absolute;
  width: 30px;
  right: -30px;
  top: -15px;
}
</style>
