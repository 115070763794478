import store from "@/store";
import UserDataService from "@/services/userDataService";

export async function userAuthenticate(to, from, next) {
    if (store.state.user) return next();

    store.commit('setIsLoading', true);
    const url = window.location.search;
    const vodapayUserId = url.substring(url.indexOf('=') + 1)
    if (vodapayUserId) {
        const userDataResponse = await login(vodapayUserId);
        store.commit('setUser', userDataResponse.data);
        await setUserData(userDataResponse.data.msisdn, next, to);
        store.commit('setIsLoading', false);
        return next();
    }
}

async function login(vodapayUserId){
    const userDataResponse = await UserDataService.vodapayUserAuth(vodapayUserId);
    await UserDataService.trackLogin(vodapayUserId);
    return userDataResponse;
}

async function setUserData(msisdn) {
    const stats = await UserDataService.getUserLandingPageStats(`${msisdn}`);
    store.commit('setUserLandingStats', stats.data);
}

