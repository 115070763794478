<template>
  <div class="d-flex flex-row justify-content-center pt-4 header-navbar">
    <img src="../assets/game/vodacom-fantasy-header.png">
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header-navbar {
  background-color: #ffffff;
}

.header-navbar img {
  width: 300px;
}
</style>
