import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";


export default class GameDataService {

    static async getActiveSports(msisdn, username) {
        return await axios.post(`${environmentConfig.predictorService}/player-landing-page`, {
            msisdn: msisdn,
            username: username
        })
    }

    static async getPlayerRoundPredictionsLive(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-player-round-predictions`, {
            msisdn: msisdn,
            roundId: roundId
        })
    }

    static async getLeagueFixtures(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/round-fixtures`, {
            msisdn: msisdn,
            roundId: roundId,
        })
    }

    static async enterSoccerRoundPredictions(fixture, msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/soccer/enter-predictions`, {
            msisdn: msisdn,
            roundId: fixture.roundId,
            soccerFixtureId: fixture.soccerFixtureId,
            eventTypeId: fixture.eventTypeId,
            team1Id: fixture.team1Id,
            team1Score: fixture.team1Score,
            team2Id: fixture.team2Id,
            team2Score: fixture.team2Score
        })
    }

    static async getPlayerPublishedRounds(msisdn, sportId) {
        return await axios.post(`${environmentConfig.predictorService}/get-player-round-history`, {
            msisdn: msisdn,
            sportId: sportId,
            promo: 1,
        })
    }

    static async enterRugbyRoundPredictions(fixture, msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/rugby/enter-predictions`, {
            msisdn: msisdn,
            roundId: fixture.roundId,
            rugbyFixtureId: fixture.rugbyFixtureId,
            winMargin: fixture.winMargin,
            teamId: fixture.teamId,
            draw: fixture.draw,
        })
    }
}
