<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column mt-4">
      <div class="d-flex flex-row justify-content-between">
        <div class="mt-3 header-text"><strong>My Results:</strong></div>
        <div class="d-flex flex-row align-items-center justify-content-center col-4">
          <button @click="sportResultsSelected(1)" :class="sportId === 1 ? 'sport-button-selected col-6' : 'sport-button-default col-6'">Soccer</button>
          <button @click="sportResultsSelected(250)" :class="sportId === 250 ? 'sport-button-selected col-6' : 'sport-button-default col-6'">Rugby</button>
        </div>
      </div>
      <div v-if="noHistoricRounds && sportId === 250" class="mt-5 text-center">No historic round results</div>
      <router-view>
        <div class="reverse-order">
          <div class="d-flex flex-column mt-2 pt-2" v-for="(leagueResult) in userResults" :key="leagueResult.id">
            <div class="d-flex flex-row align-items-center round-header">
              <div class="d-flex flex-row align-items-center col-6">
                <img class="league-icon-img" :src="this.findLeagueLogoImageModule(leagueResult[0].LeagueName)">
                <div class="round-number-text">{{ leagueResult[0].LeagueName.toUpperCase() }}</div>
              </div>
            </div>
            <div class="pt-2 table-responsive">
              <table class="table-container table-borderless col-12">
                <thead>
                <tr class="table-headers text-center">
                  <th scope="col">Teams</th>
                  <th scope="col">Game Result</th>
                  <th scope="col">Your Prediction</th>
                  <th class="your-points-header" scope="col">Your Points</th>
                </tr>
                </thead>
                <tbody class="table-body text-center" v-for="match in leagueResult" :key="match.id">
                <tr class="table-row">
                  <td class="d-flex flex-row justify-content-center">
                    <div class="d-flex flex-column align-items-center text-center">
                      <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team1Name)[0]">
                    </div>
                    <div class="d-flex flex-column align-items-center text-center">
                      <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team2Name)[0]">
                    </div>
                  </td>
                  <td>{{ match.Team1Result }}-{{ match.Team2Result }}</td>
                  <td v-if="sportId === 1">{{ match.Team1Score }}-{{ match.Team2Score }}</td>
                  <td v-if="sportId === 250">
                    <div v-if="match.WinMargin != null">
                      {{ match.WinMargin }} ({{ shortenRugbyTeamNames(match.UsersPickWinningTeamName) }})
                    </div>
                    <div v-if="match.WinMargin === null">
                      Draw
                    </div>
                  </td>
                  <td class="your-points-header">{{ match.Points }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </router-view>
    </div>
    <div class="d-flex flex-column align-items-center text-center justify-content-center">
      <div class="d-flex flex-column align-items-center col-9 bottom-container">
        <button @click="backButtonClicked" class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3 back-button">Back</button>
        <button @click="openCloseEarnPointsPopUp"
                class="col-9 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-3 mb-2 earn-points-button">
          How do I earn points?
        </button>
      </div>
    </div>
    <div v-if="earnPointsPopup && sportId === 1"
         class="d-flex flex-column align-items-center earn-points-popup col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img @click="openCloseEarnPointsPopUp()" class="exit-popup-img" :src="require(`../assets/game/exit-icon.png`)">
      <div class="text-center col-10 pt-2 pb-2 popup-header-breakdown-text">
        Take a look at the breakdown below of how to earn points:
      </div>
      <table class="table-popup table-borderless">
        <thead class="table-header">
        <tr>
          <th scope="col">Description</th>
          <th class="d-flex flex-row justify-content-end" scope="col">Points</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th class="point-description" scope="row">Home score</th>
          <td class="d-flex flex-row justify-content-end">6</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">Away score</th>
          <td class="d-flex flex-row justify-content-end">6</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">Result</th>
          <td class="d-flex flex-row justify-content-end">10</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">All correct</th>
          <td class="d-flex flex-row justify-content-end">10</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="earnPointsPopup && sportId === 250"
         class="d-flex flex-column align-items-center earn-points-popup col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img @click="openCloseEarnPointsPopUp()" class="exit-popup-img" :src="require(`../assets/game/exit-icon.png`)">
      <div class="text-center col-10 pt-2 pb-2 popup-header-breakdown-text">
        Take a look at the breakdown below of how to earn points:
      </div>
      <table class="table-popup table-borderless">
        <thead class="table-header">
        <tr>
          <th scope="col">Description</th>
          <th class="d-flex flex-row justify-content-end" scope="col">Points</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th class="point-description" scope="row">Correct Team</th>
          <td class="d-flex flex-row justify-content-end">5</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">Win Margin (within 5 points)</th>
          <td class="d-flex flex-row justify-content-end">5</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">Exact Margin</th>
          <td class="d-flex flex-row justify-content-end">10</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">Draw</th>
          <td class="d-flex flex-row justify-content-end">30</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import GameDataService from "@/services/gameDataService";
import {mapState, mapMutations} from 'vuex';

export default {
  name: "MyResults",
  data() {
    return {
      earnPointsPopup: false,
      noHistoricRounds: undefined,
      sportId: 1,
      selectedSport: 'soccer',
    }
  },
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async sportResultsSelected(sportId) {
      this.setIsLoading(true);
      this.sportId = sportId;
      this.selectedSportType()
      let resultsResponse = await GameDataService.getPlayerPublishedRounds(`${this.user.msisdn}`, this.sportId);
      this.userPredictions = resultsResponse.data.recordset;
      this.sortUserRounds();
      this.$router.push({name: 'MyResults', params: {sportId: this.sportId}});
      this.setIsLoading(false);
    },
    selectedSportType() {
      const sportIdToName = {
        1: 'soccer',
        250: 'rugby',
      };
      this.selectedSport = sportIdToName[this.sportId] || '';
    },
    findLeagueLogoImageModule(leagueName) {
      let teamNameReformat = leagueName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/leagueLogos/${teamNameReformat}-logo-default.png`)]
      } catch (e) {
        return [require(`@/assets/teamBadges/soccer/default-badge.png`)]
      }
    },
    shortenRugbyTeamNames(teamName) {
      teamName = teamName.replace(' Rugby', "");
      return teamName
    },
    findTeamImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/teamBadges/${this.selectedSport}/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`@/assets/teamBadges/soccer/default-badge.png`)]
      }
    },
    backButtonClicked() {
      this.$router.push('/landing-page');
    },
    openCloseEarnPointsPopUp() {
      this.earnPointsPopup = this.earnPointsPopup === false;
    },
    async getUserRoundHistory() {
      let userRoundHistoryResponse = await GameDataService.getPlayerPublishedRounds(`${this.user.msisdn}`, this.sportId);
      this.userPredictions = userRoundHistoryResponse.data.recordset;
    },
    sortUserRounds() {
      this.userResults = this.userPredictions.reduce((roundsResults, item) => {
        const round = (roundsResults[item.RoundID] || []);
        round.push(item);
        roundsResults[item.RoundID] = round;
        return roundsResults;
      }, {});
      this.checkForNoResults();

    },
    checkForNoResults() {
      if (Object.keys(this.userResults).length === 0) this.noHistoricRounds = true;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getUserRoundHistory();
    this.sortUserRounds();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 150px;
}

.bottom-container {
  position: fixed;
  bottom: 0;
}

.sport-button-selected, .sport-button-default {
  height: 22px;
  border: 0;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: 600;
}

.sport-button-selected {
  background-color: #EF151C;
  color: #FFFFFF;
}

.sport-button-default {
  background-color: transparent;
  color: #000000;
  border: 0.1px solid black;
}

.table-popup {
  width: 90%;
}

.table-header {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.point-description {
  font-weight: 400;
  padding-top: .5em;
  padding-bottom: .5em;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

.earn-points-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 15px;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

.popup-header-breakdown-text {
  font-weight: 700;
}

.earn-points-button {
  font-size: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 35px;
  font-weight: 700;
  border: 0;
}

.back-button {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 50px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
}

.header-text {
  margin-left: 20px;
}

.round-header {
  border-radius: 8px;
  font-weight: 600;
  height: 34px;
  font-size: 12px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.league-icon-img {
  margin-left: 8px;
  height: 20px;
}

.round-number-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

.table-headers {
  font-size: 10px;
  font-weight: 600;
}

.your-points-header {
  color: #EF151C;
}

.table-body {
  font-size: 10px;
  font-weight: 500;
}

.table-row {
  border-bottom: 0.2px solid #a8a8a8;
}

.user-points-text {
  font-weight: 600;
}

td {
  text-align: center;
  vertical-align: middle;
}

.user-points-text {
  font-weight: 600;
}

td {
  text-align: center;
  vertical-align: middle;
}

.select-leagues-text {
  font-weight: 600;
}

.sport-name-highlighted {
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  font-size: 14px;
}

.sport-name-default {
  margin-top: 2px;
  color: #a8a8a8;
  font-size: 14px;
}

.round-date {
  padding-right: 10px;
}

.reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

.badge-icon-img {
  width: 24px;
  margin: 2px;
}

</style>
