<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center mt-3">
      <img src="../assets/game/header-icon-1.png">
      <div class="d-flex flex-row justify-content-center mt-4">
        <div class="d-flex flex-column justify-content-center user-stats-container m-1 col-4">
          <div class="stats-header-text mt-2">MY POINTS</div>
          <div class="stats-points-text">{{ userPoints }}</div>
        </div>
        <div class="d-flex flex-column justify-content-center user-stats-container m-1 col-4">
          <div class="stats-header-text mt-2">ROUNDS ENTERED</div>
          <div class="stats-points-text">{{ userRoundsEntered }}</div>
        </div>
      </div>
      <div class="mt-4">
        <button v-if="!roundEntered" @click="routeToMakePredictions()" class="enter-prediction-button col-11">
          ENTER THIS ROUNDS
          PREDICTION
        </button>
        <button v-if="roundEntered" @click="routeToMakePredictions()" class="enter-prediction-button col-11">EDIT
          THIS ROUNDS
          PREDICTION
        </button>
      </div>
      <div class="mt-2">
        <button @click="routeToHowItWorks()" class="how-it-works-button col-9 m-1">How it works</button>
        <button class="prize-button col-2 m-1">
          <img @click="routeToResults()" class="prize-icon" src="../assets/game/results-icon.png">
        </button>
      </div>
      <div class="mt-3">By participating, you <u class="terms-conditions-text" @click="termsConditionsClicked()">agree
        to the Terms and Conditions</u></div>
    </div>
  </div>
</template>

<script>
import GameDataService from "@/services/gameDataService";
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'LandingPage',
  components: {},
  data() {
    return {
      userPoints: undefined,
      userRoundsEntered: undefined,
      promoRounds: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading', 'userLandingStats', 'roundEntered', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setRoundEntered', 'setUserSubscriptionStatus']),
    setUserLandingPageStats() {
      this.userRoundsEntered = this.userLandingStats.userStats.roundsPreviouslyEntered;
      this.userPoints = this.userLandingStats.userStats.totalSeasonPoints;
      this.setUserSubscriptionStatus(this.user.status)
    },
    routeToMakePredictions() {
      if (this.promoRounds === false) {
        this.$router.push({name: `NoRoundsPage`});
      } else this.$router.push({name: `MakePredictionsSoccer`, params: {roundId: this.roundId}});
    },
    routeToHowItWorks() {
      this.$router.push('/how-it-works');
    },
    routeToResults() {
      this.$router.push('/my-results/1');
    },
    async setPromoRound() {
      const upcomingSports = await GameDataService.getActiveSports(`${this.user.msisdn}`, `${this.user.username}`)

      if (upcomingSports.data.length > 0) {
        let filteredPromo = upcomingSports.data.filter(function (sport) {
          return sport.Promo === true;
        })
        if (filteredPromo.length > 0) {
          this.roundId = filteredPromo[0].RoundID
          this.hasPredictions = filteredPromo[0].HasPredictions
          this.toggleEnterEditPredictionButton()
        } else {
          this.promoRounds = false
        }
      } else {
        this.promoRounds = false
      }
    },
    toggleEnterEditPredictionButton() {
      if (this.hasPredictions === true) this.setRoundEntered(true)
    },
    termsConditionsClicked() {
      my.postMessage('terms');
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.setUserLandingPageStats();
    await this.setPromoRound()
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
}

.user-stats-container {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 110px;
  border-radius: 10px;
}

.stats-header-text {
  font-weight: 600;
  font-size: 12px;
}

.stats-points-text {
  font-size: 44px;
  font-weight: 700;
}

.enter-prediction-button {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 50px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
}

.how-it-works-button {
  padding-bottom: 5px;
  height: 45px;
  border-radius: 10px;
  border: 0.5px solid #EF151C;
  font-size: 16px;
}

.prize-button {
  height: 45px;
  border-radius: 10px;
  border: 0.5px solid #EF151C;
  font-size: 16px;
  padding-bottom: 2px;
}

.prize-icon {
  width: 28px;
}

.terms-conditions-text {
  font-weight: 700;
  cursor: pointer;
}

</style>
