import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        user: undefined,
        userLandingStats: undefined,
        roundEntered: undefined,
        subscriptionStatus: undefined,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setUser(state, data) {
            state.user = data;
        },
        setUserLandingStats(state, userLandingStats) {
            state.userLandingStats = userLandingStats;
        },
        setRoundEntered(state, roundEntered) {
            state.roundEntered = roundEntered;
        },
        setUserSubscriptionStatus(state, subscriptionStatus){
            state.subscriptionStatus = subscriptionStatus;
        },
    },
    actions: {},
    modules: {}
})
