<template>
  <div class="d-flex flex-column align-items-center body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center col-12 mt-3">
      <img src="../assets/game/header-icon-2.png">
    </div>
    <div class="d-flex flex-row justify-content-around round-date-time col-11 mt-3 p-1">
      <div>{{ formattedDate }}</div>
      <div>{{ formattedTime }}</div>
    </div>
    <div class="text-center col-11 make-prediction-team-margin-container pb-3"
         v-for="(fixture, index) in fixturesArray" :key="index">
      <div v-if="checkMatchNotStarted(fixture)">
        <div class="pt-2 winner-margin-text">
          Select a winner and input a win margin
        </div>
        <div class="d-flex flex-row justify-content-center team-select-container pt-1">
          <div @click="teamSelectedToWin(fixture, fixture.Team1Id, 1)"
               :class=" fixture.teamNumber === 1 || fixture.WinningTeam === fixture.Team1Id ? 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
            <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team1Name)[0]"/>
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
          </div>
          <div @click="resultDrawSelected(fixture)"
               class="d-flex flex-column justify-content-center draw-container p-2 mt-1">
            <div
                :class="fixture.teamNumber === null || fixture.WinningTeam === null  ? 'd-flex flex-column justify-content-center align-content-center align-items-center secondary-text draw-box-selected' : 'd-flex flex-column justify-content-center align-content-center align-items-center draw-box-default'">
              draw
            </div>
          </div>
          <div @click="teamSelectedToWin(fixture, fixture.Team2Id, 2)"
               :class=" fixture.teamNumber === 2 || fixture.WinningTeam === fixture.Team2Id  ?'d-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
            <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team2Name)[0]"/>
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
          </div>
        </div>
        <div v-if="fixture.Draw || fixture.WinMargin" class="d-flex flex-row justify-content-center pt-2">
          <button @click="editPredictionButtonClicked(fixture)"
                  class="edit-prediction-button">
            Edit Predictions
          </button>
        </div>
        <div class="d-flex flex-row justify-content-center pt-2">
          <div
              class="d-flex flex-column justify-content-center win-margin-container"
              v-if="fixture.Draw === false || fixture.Draw === null">
            {{ fixture.WinMargin }}
          </div>
        </div>
        <div v-if="fixture.isSelected" class="d-flex flex-row align-items-center justify-content-center pt-2">
          <img class="col-1 m-2" src="../assets/game/left-arrow-icon.png">
          <div class="scrollable-container col-8">
            <div @click="setWinMargin(fixture, option)" class="option" v-for="option in options">
              {{ option }}
            </div>
          </div>
          <img class="col-1 m-2" src="../assets/game/right-arrow-icon.png">
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center pt-3 col-11">
      <button @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled"
              class="submit-predictions-button">
        Submit Predictions
      </button>
    </div>
    <div class="d-flex flex-column justify-content-center col-9">
      <button @click="backButtonClicked" class="mt-3 back-button">Back</button>
    </div>
    <div v-if="successfulSubmissionPopup"
         class="d-flex flex-column text-center align-items-center prediction-submitted-container col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="col-10 submission-header-text mt-3">
        Prediction Submitted Successfully
      </div>
      <div class="col-10 submission-secondary-text mt-2">
        Thanks for predicting the score for this weeks round. All the points you earn will be entered into the grand
        prize draw.
      </div>
      <div v-if="subscriptionStatus !== 'Subscribed'" class="col-10 submission-secondary-text mt-3">
        Do you want to WIN airtime daily & predict more of the world greatest leagues? <strong>Join Vodacom Fantasy
        League VIP today!</strong>
      </div>
      <button v-if="subscriptionStatus === 'Subscribed'" class="m-4 col-11 join-now-button"
              @click="onPlayFantasyFootballClicked">PLAY FANTASY FOOTBALL
      </button>
      <button v-if="subscriptionStatus !== 'Subscribed'" class="m-4 col-11 join-now-button" @click="onJoinNowClicked">
        JOIN VIP NOW
      </button>
      <div class="d-flex flex-row col-11 mb-4">
        <button @click="editPredictionsClicked" class="edit-prediction-button-popup col-10">Edit Prediction</button>
        <button @click="homeButtonClicked" class="home-button col-2">
          <img class="col-6" src="../assets/game/home-icon-white.png">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import GameDataService from "@/services/gameDataService";

export default {
  name: "MakePredictionsRugby",
  computed: {
    ...mapState(['isLoading', 'roundEntered', 'user', 'subscriptionStatus'])
  },
  data() {
    return {
      successfulSubmissionPopup: false,
      submitButtonDisabled: false,
      fixturesArray: undefined,
      formattedTime: undefined,
      formattedDate: undefined,
      pointsScoringBreakdown: undefined,
      earnPointsPopup: false,
      noTeamSelectedError: false,
      displayClubName: false,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setRoundEntered']),
    dateConversion(fixtures) {
      this.formattedDate = moment(fixtures[0].StartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtures[0].StartDate).format('h:mma');
    },
    onPlayFantasyFootballClicked() {
      my.postMessage();
    },
    editPredictionsClicked() {
      this.submitButtonDisabled = false;
      this.successfulSubmissionPopup = false;
    },
    homeButtonClicked() {
      this.setRoundEntered(true);
      this.$router.push('/landing-page');
    },
    onJoinNowClicked() {
      my.postMessage();
    },
    backButtonClicked() {
      this.$router.push('/landing-page');
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/rugby/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`@/assets/teamBadges/soccer/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    openCloseEarnPointsPopUp() {
      this.earnPointsPopup = this.earnPointsPopup === false;
    },
    setWinMargin(fixture, winMargin) {
      fixture.WinMargin = winMargin
      fixture.Draw = false;
      fixture.isSelected = false;
    },
    teamSelectedToWin(fixture, teamId, teamNumber) {
      fixture.teamNumber = teamNumber
      fixture.isSelected = true;
      fixture.teamId = teamId;
      fixture.draw = 0;
      fixture.WinningTeam = teamId;
      this.noTeamSelectedError = false;
      fixture.winMarginText = true;
    },
    resultDrawSelected(fixture) {
      fixture.teamNumber = null;
      fixture.draw = 1;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.isSelected = false;
      fixture.WinningTeam = null;
      this.noTeamSelectedError = false;
    },
    addRoundFixturesDefaultWinMargin(fixtures) {
      this.fixturesArray = [];
      fixtures.forEach((fixture) => {
        fixture.WinMargin = null;
        fixture.isSelected = undefined;
        fixture.teamNumber = undefined;
        fixture.draw = null;
        this.fixturesArray.push(fixture);
      });
    },
    editPredictionButtonClicked(fixture) {
      fixture.Draw = undefined;
      fixture.Points = 0;
      fixture.isSelected = null;
      fixture.teamNumber = undefined;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.WinningTeam = undefined;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.fixturesArray.asyncForEach(async (match) => {
        this.checkForWinMargin(match);
        this.checkForNoSelection(match)
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.roundId;
        fixture.rugbyFixtureId = match.FixtureID || match.RugbyFixtureID;
        fixture.winMargin = match.WinMargin;
        fixture.teamId = match.teamId;
        fixture.draw = match.draw;

        await GameDataService.enterRugbyRoundPredictions(fixture, this.user.msisdn);
      });
      this.successfulSubmissionPopup = true;
    },
    checkForWinMargin(match) {
      if (match.teamId && match.WinMargin === null) {
        match.WinMargin = 1;
      }
    },
    checkForNoSelection(match) {
      if (!match.teamId && !match.WinMargin) {
        match.draw = 1;
      }
    },
    setWinningTeamIdAndWinMargin() {
      this.fixturesArray.forEach((fixture) => {
        fixture.teamId = fixture.WinningTeam
      })
    },
    async checkIfRoundPreviouslyEntered() {
      this.fixturesArray = [];
      this.leagueFixtures = await GameDataService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      let userFixturesEnteredResponse = await GameDataService.getPlayerRoundPredictionsLive(`${this.user.msisdn}`, `${this.$route.params.roundId}`)

      if (userFixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.fixturesArray = userFixturesEnteredResponse.data.roundPredictions;
        this.setWinningTeamIdAndWinMargin();
      } else {
        this.addRoundFixturesDefaultWinMargin(this.leagueFixtures.data.fixtures);
      }
      this.pointsScoringBreakdown = this.leagueFixtures.data.pointsScoringBreakdown[0];
    },
    checkRoundId() {
      this.roundId = this.$route.params.roundId
    },
    scrollerSetMargin() {
      this.options = [];
      for (let i = 1; i < 121; i++) {
        this.options.push(i);
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered()
    this.dateConversion(this.fixturesArray);
    this.checkRoundId();
    this.scrollerSetMargin()
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.scrollable-container {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  font-size: 15px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.team-name {
  font-size: 10px;
}

.option {
  background-color: #2D343B;
  width: 50px !important;
  height: 40px;
  border-radius: 6px;
  margin-right: 0.6rem;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  font-size: 1.8rem;
}

.team-select-container {
  position: relative;
}

.edit-prediction-button {
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.win-margin-container {
  height: 50px;
  width: 50px;
  color: white;
  font-size: 1.8rem;
  border-radius: 10px;
  background-color: #2D343B;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.make-predictions-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.predict-scores-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.earn-points-button {
  font-size: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 30px;
  font-weight: 700;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  font-size: 15px;
}

.make-prediction-team-margin-container {
  border-bottom: 1px solid gray;
}

.winner-margin-text {
  font-size: 14px;
  font-weight: 600;
}

.team-badge-container-default {
  border: 2px solid black;
  border-radius: 8px;
  height: 80px;
  width: 100px;
}

.team-badge-container-selected {
  border: 2px solid red;
  border-radius: 8px;
  height: 80px;
  width: 100px;
  background: #FFFFFF;
}

.draw-container {
  height: 70px;
}

.draw-box-selected {
  border: 2px solid red;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
  background: #FFFFFF;
}

.draw-box-default {
  border: 2px solid black;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
}

.win-margin-box {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}


.win-margin-box input {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}

::placeholder {
  color: white;
}

.earn-points-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 15px;
}

.popup-header-breakdown-text {
  font-weight: 700;
}

table {
  width: 90%;
}

.table-header {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.point-description {
  font-weight: 400;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

tr {
  border-bottom: .2px solid gray;
}

.submit-predictions-button {
  border-radius: 12px;
  height: 50px;
  font-size: 18px;
  border: 0;
  color: #FFFFFF;
  background-color: #EF151C;
}

.back-button {
  height: 45px;
  border-radius: 10px;
  border: 0.5px solid #EF151C;
  font-size: 15px;
  background-color: #e8e8e8;
}

.error-message-text {
  font-weight: 600;
}

.carousel__item {
  height: 40px;
  width: 100%;
  background-color: #3a3a3a;
  color: white;
  font-size: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 2px;
}

.prediction-submitted-container {
  position: fixed;
  bottom: 0;
  color: #FFFFFF;
  background-color: #EF151C;
  border-bottom-right-radius: 35px;
  border-top-left-radius: 35px
}

.submission-header-text {
  font-weight: 600;
  font-size: 20px;
  border-bottom: .5px solid #FFFFFF;
}

.join-now-button {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  height: 45px;
  font-weight: 700;
}

.home-button {
  height: 45px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #EF151C;
  margin-left: 5px;
}

.edit-prediction-button-popup {
  padding-bottom: 5px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #EF151C;
}

@media screen and (max-width: 392px) {
  .earn-points-button {
    font-size: 12px;
  }
}
</style>
