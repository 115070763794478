<template>
  <Header/>
  <div class="d-flex row justify-content-center content-body">
    <LoadingScreen class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading"/>
    <router-view class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3"></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header";
import LoadingScreen from "@/components/LoadingScreen";
import {mapState, mapMutations} from "vuex";

export default {
  components: {Header, LoadingScreen},
  computed: {
    ...mapState(['isLoading'])
  },
  data() {
    return {}
  },
  async beforeMount() {
  },
  methods: {
    ...mapMutations(['setIsLoading'])
  },
  watch: {}
};
</script>

<style>

.content-body {
  position: absolute;
  height: auto;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
}

</style>
