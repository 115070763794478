import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class UserDataService {

    static async vodapayUserAuth(userId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.vodaPayIntegrationHost}/vodapay-login`,
            data: {
                vodapayUserId: userId,
            }
        })
    }

    static async trackLogin(userId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.vodaPayIntegrationHost}/track-login`,
            data: {
                tokenMsisdn: userId,
                isScorePredictor: 1
            }
        })
    }

    static async getUserLandingPageStats(msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/get-user-stats`, {
            msisdn: msisdn,
            promo: 1,
        })
    }
}
