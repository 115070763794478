<template>
  <div class="d-flex flex-column align-items-center text-center body-container">
    <img class="spinner-gif" src="../assets/game/loading-screen-spinner.gif" width="100">
  </div>
</template>

<script>
export default {
  name: "LoadingScreen"
}
</script>

<style scoped>
.body-container {
  position: relative;
  height: 90vh;
  background-color: #e8e8e8;
}

.spinner-gif {
  position: absolute;
  margin-top: 30vh;
}

</style>
